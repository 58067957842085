:root {
	--primary: #4cd4ca;
	--dark: #334b49;
	--light-blue: #9ec0ff;
	--dark-blue: #668bce;
	--gray: #96b1ad;
	font-size: 16px;
}

body {
	background-color: var(--dark);
}

h3 {
	font-size: 1.3rem;
}

h4 {
	font-size: 1.2rem;
}

h5 {
	font-size: 1.1rem;
}
.about {
	background-color: var(--light-blue);
	border-top-right-radius: 0.3rem;
	border-bottom-right-radius: 0.3rem;
}

.dark-bg {
	background-color: var(--dark);
}

.light-blue-bg {
	background-color: var(--light-blue);
}

.form-check-inline {
	margin: 1rem;
}

.accordion-button {
	font-size: 1.1rem;
	font-weight: 500;
}

@media only screen and (max-width: 500px) {
	.accordion-button {
		font-size: 1rem;
	}
	h3,
	h4,
	h5 {
		font-size: 1rem;
	}
}
